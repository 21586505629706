/*--------------------------------------------------------------------------*
 * movie
 *--------------------------------------------------------------------------*/
$(function(){
    $( window ).ready(function(){
        var video = document.getElementsByTagName('video')[0];
        video.controls = false;
        video.addEventListener('ended', function() {
            video.load();
            $('.top-movie').addClass('finish');
            video.autoplay = false;
        }, false);
    });
});
